<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
    style="position:absolute;top:0"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Solicitação"
          class="px-5 py-3"
        >
          <v-subheader class="display-1 mt-3">
            Dados Equipamento:
          </v-subheader>

          <v-form class="pt-1" @submit.prevent="postRequest">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Modelo do Equipamento"
            >
              <v-text-field
                label="Modelo do Equipamento*"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="productmodel"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Failure"
            >

              <v-textarea
                name="input-7-1"
                label="Defeito Apresentado*"
                value=""
                hint="Escreva o que você precisa"
                class="ml-4"
                :error-messages="errors"
                v-model="failure"
              />

            </validation-provider>

            <v-subheader class="display-1 mt-3">
              Dados Pessoais:
            </v-subheader>

            <v-row>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Nome"
                >

                  <v-text-field
                    label="Nome*"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="name"
                  />

                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="CPF/CNPJ"
                >

                  <v-text-field
                    label="CPF/CNPJ*"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="cpf"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                  />

                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Whatsapp"
                >

                  <v-text-field
                    label="Whatsapp"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="whatsapp"
                    v-mask="'(##)#-####-####'"
                  />

                </validation-provider>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Telefone"
                  outlined
                  class="ml-4"
                  v-model="tel"
                  v-mask="'(##)####-####'"
                />

              </v-col>
            </v-row>

            <v-text-field
              label="Email"
              outlined
              class="ml-4"
              :error-messages="errors"
              v-model="email"
            />

            <v-subheader class="display-1 mt-3">
              Endereço:
            </v-subheader>

            <v-row>
              <v-col md="2" sm="12" xs="12">

                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="CEP"
                >
                  <v-text-field
                    label="CEP*"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="cep"
                    v-mask="'##.###-###'"
                    @keyup="searchCep()"
                  />

                </validation-provider>
              </v-col>
            </v-row>

            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Endereço"
            >

              <v-text-field
                label="Endereço*"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="address"
              />

            </validation-provider>



            <v-text-field
              label="Complemento"
              outlined
              class="ml-4"
              :error-messages="errors"
              v-model="complement"
            />


            <v-row>
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Cidade"
                >
                  <v-text-field
                    label="Cidade*"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="city"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Bairro"
                >
                  <v-text-field
                    label="Bairro*"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="district"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Estado"
                >
                  <v-text-field
                    label="Estado*"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="state"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-textarea
                name="input-7-1"
                label="Observação"
                value=""
                hint="observações"
                class="ml-4"
                v-model="description"
              />
            </v-row>


            <v-alert
              border="left"
              color="indigo"
              dark
            >
               Após o envio dos dados acima necessários, nossa equipe responsável entrará em contato para enviar o  link de postagem do seu equipamento. As despesas de transporte (IDA E VOLTA), são por responsabilidade do cliente. Caso deseje enviar por alguma transportadora, informe na observação.
            </v-alert>

            <!--v-alert
              v-if="code"
              border="left"
              color="green"
              dark
            >
              Requisição Enviada, acompanhe sua solicitação com o Código: {{this.code}} em https://nucleodigitalgoiania.com/#/acompanhar.
            </v-alert-->

            <v-alert
              v-if="code"
              border="left"
              color="green"
              dark
            >
              Requisição Enviada, nossa equipe entrará em contato em breve.
            </v-alert>


            <v-card-actions class="pl-0">
              <v-btn
                v-if="enableSubmit"
                color="success"
                min-width="100"
                type="submit"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ServiceRequestsService from '@/services/ServiceRequestsService'
  import axios from 'axios'

  export default {
    name: 'Request',

    components: { 

    },

    data: () => ({
      name: "",
      description: "",
      whatsapp: "",
      email: "",
      tel: "",
      productmodel: "",
      failure: "",
      cpf: "",
      cep: "",
      address: "",
      complement: "",
      city: "",
      district: "",
      state: "",
      code: null,
      enableSubmit: true
    }),

    computed: {
      
    },

    methods: {

      postRequest() {
        let data = []


        data['name'] = this.name
        data['description'] =  this.description
        data['whatsapp'] =  this.whatsapp
        data['email'] =  this.email
        data['tel'] =  this.tel
        data['productmodel'] =  this.productmodel
        data['failure'] =  this.failure
        data['cpf'] =  this.cpf
        data['cep'] =  this.cep
        data['address'] =  this.address
        data['complement'] =  this.complement
        data['city'] =  this.city
        data['district'] =  this.district
        data['state'] =  this.state

        ServiceRequestsService.postServiceRequest(data)
        .then((response) => {
          console.log(response)
          if(response.data.message == "Error"){
            throw 500
          }
          this.code = response.data.code
          this.$toast.success('Solicitação Enviada.', '',{position:'topRight'})
          this.enableSubmit = false
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao enviar solicitação cheque os campos.', '',{position:'topRight'})
        })
      },

      searchCep () {
        if(this.cep.length == 10) {
          axios.get(`https://viacep.com.br/ws/${ this.cep.replace('-', '').replace('.', '') }/json/`)
          .then((response) => {
            this.address = response.data.logradouro
            this.city = response.data.localidade
            this.district = response.data.bairro
            this.state = response.data.uf
          })
          .catch( error => console.log(error) )
        }
      },

    }
  }
</script>